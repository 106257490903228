import { mapGetters } from "vuex";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import ApiService from "@/core/services/api.service";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import SchedulerApiService from "@/core/services/api.service.scheduler";
import moment from "moment";

export default {
  props: {
    modal: {
      default: false
    },
    value: Boolean
  },
  name: "banner-instance",
  data() {
    return {
      valid: false,
      isSaving: false,
      bannerInstanceId: null,
      oobleeBannerInstanceBulkInsert: {
        oobleeBannerDefinition: null,
        start: null,
        end: null,
        cityIds: null,
        allCities: false,
        active: false
      },
      countries: [],
      selectedCountryId: null,
      cities: [],
      bannerDefinitions: [],
      search: "",
      citiesIsLoading: false,
      bannersIsLoading: false,
      dateFromMenu: false,
      dateToMenu: false,
      requiredRule: [v => !!v || "Field is required"],
      dateRule: [v => !!v || "Field is required", v => v.length > 10 || "Field is required"]
    };
  },
  created() {
    let title = "Insert new banner instance";
    this.$store.dispatch(SET_BREADCRUMB, [{ title: title }]);
    this.searchBannerDefinitions();
  },
  async mounted() {
    await SchedulerApiService.init();
    this.getCountries();
  },
  methods: {
    previousState() {
      this.$router.go(-1);
    },
    removeCityFromList(item) {
      const index = this.oobleeBannerInstanceBulkInsert.cityIds.indexOf(item.id);
      if (index >= 0) this.oobleeBannerInstanceBulkInsert.cityIds.splice(index, 1);
    },
    searchBannerDefinitions() {
      // Items have already been loaded
      if (this.bannerDefinitions.length > 0) return;

      // Items have already been requested
      if (this.bannersIsLoading) return;

      this.bannersIsLoading = true;
      let params = {};
      params["page"] = 1;
      params["size"] = 999;
      ApiService.query("api/banner-definition", {
        params
      })
        .then(res => {
          this.bannerDefinitions = res.data.content;
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.bannersIsLoading = false));
    },
    getCountries() {
      SchedulerApiService.getCountries().then(res => {
        this.countries = res.data || [];
      });
    },
    onSelectCountry() {
      this.getCities(this.selectedCountryId);
    },
    async getCities(countryId) {
      if (!countryId) return;
      const res = await SchedulerApiService.getCities({ countryId });
      this.cities = res.data || [];
    },
    isNonEmptyArrayOfStrings(value) {
      return Array.isArray(value) && value.length && value.every(item => typeof item === "number");
    },
    validateAndSave() {
      if (this.$refs.form.validate()) {
        this.saveBannerInstance(this.oobleeBannerInstanceBulkInsert);
      }
    },
    saveBannerInstance(oobleeBannerInstanceBulkInsert) {
      this.loading = true;
      let bannerObject = {};
      bannerObject["id"] = oobleeBannerInstanceBulkInsert.oobleeBannerDefinition;
      this.$log.debug("bannerObject:", bannerObject);
      if (oobleeBannerInstanceBulkInsert.cityIds === null) {
        oobleeBannerInstanceBulkInsert.cityIds = [0];
      }
      this.$log.debug("oobleeBannerInstanceBulkInsert:", oobleeBannerInstanceBulkInsert);
      oobleeBannerInstanceBulkInsert.oobleeBannerDefinition = bannerObject;
      oobleeBannerInstanceBulkInsert["countryId"] = this.selectedCountryId || null;
      ApiService.post(`api/banner-instance/bulk-insert`, oobleeBannerInstanceBulkInsert)
        .then(response => {
          this.$log.debug("Banner instance created: " + response);
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => {
          this.loading = false;
          this.previousState();
        });
    }
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    ...mapGetters(["currentUser"]),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.clearFields();
          this.$emit("input", value);
        }
      }
    },
    dateStartFormatted: {
      get() {
        if (
          this.oobleeBannerInstanceBulkInsert.start == null ||
          this.oobleeBannerInstanceBulkInsert.start === ""
        )
          return null;
        return moment
          .utc(this.oobleeBannerInstanceBulkInsert.start)
          .local()
          .format("yyyy-MM-DD HH:mm:ss");
        // return moment(this.oobleeBannerInstance.start, "YYY-MM-DDTHH:mm:ssZ").format("yyyy-MM-DD HH:mm:ss");
      },
      set(value) {
        this.oobleeBannerInstanceBulkInsert.start = value;
      }
    },
    dateEndFormatted: {
      get() {
        if (
          this.oobleeBannerInstanceBulkInsert.end == null ||
          this.oobleeBannerInstanceBulkInsert.end === ""
        )
          return null;
        return moment
          .utc(this.oobleeBannerInstanceBulkInsert.end)
          .local()
          .format("yyyy-MM-DD HH:mm:ss");
      },
      set(value) {
        this.oobleeBannerInstanceBulkInsert.end = value;
      }
    }
  }
};
